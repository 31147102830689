import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const selectionAction = (state = null, action = {}) => {
  switch (action.type) {
    case Type.SET_CURRENT_POINT:
      return action.data;
    case Type.UPDATE_CURRENT_POINT: {
      if(state && state.loading && state.index === action.data.index) {
        return action.data;
      }
      return state;
    }
    case FaerunType.SET_DATA:
      return null;
    
    default: return state;
  }
};

export default selectionAction;
