import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const hoverAction = (state = {}, action = {}) => {
  switch (action.type) {
    case Type.SET_HOVER_DATA:
      return action.data;
    case Type.UPDATE_HOVER_DATA: {
      if(state.point && state.point.loading && state.point.index === action.data.index) {
        return { ...state, point: action.data};
      }
      return state;
    }
    case FaerunType.SET_DATA:
      return {};
    
    default: return state;
  }
};

export default hoverAction;
