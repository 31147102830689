import * as Ducks from "./actions";

const { Type, ...Action } = Ducks;

export { Type, Action };

const authAction = (state = null, action = {}) => {
  switch (action.type) {

    case Type.SET_USER:
      return action.data;

    case Type.CLEAR_USER:
      return null;

    default: return state;
  }
};

export default authAction;
