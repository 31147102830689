import * as Ducks from "./actions";

const { Type, Request, ...Action } = Ducks;

export { Type, Action, Request };

const uiAction = (state = { error: {}, loading: {}, histogramSelector: {} }, action = {}) => {
  switch (action.type) {
    case Type.SET_ERROR:
      return { ...state, error: { ...state.error, [action.request]: action.value } };

    case Type.SET_LOADING:
      return { ...state, loading: { ...state.loading, [action.request]: action.value } };

    case Type.SET_HISTOGRAM_SELECTOR:
      return {...state, histogramSelector: action.data };

    default: return state;
  }
};

export default uiAction;
