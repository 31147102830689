import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const scrollSelectionAction = (state = { selection: [], last: [] }, action = {}) => {
  switch (action.type) {
    case Type.SET_SCROLL_SELECTION:
      return { selection: action.selection, last: action.last };
    case Type.CLEAR_SCROLL_SELECTION: {
      return { selection: [], last: [] };
    }
    case FaerunType.SET_DATA:
      return { selection: [], last: [] };
    
    default: return state;
  }
};

export default scrollSelectionAction;
