import * as Ducks from "./actions";
import * as Api from "./api"; 

const { Type, ...Action } = Ducks;

export { Type, Action, Api };

const tmapsAction = (state = [], action = {}) => {
  switch (action.type) {
    case Type.SET_TMAPS:
      return action.data;
    case Type.ADD_TMAP: {
      return [...state, action.data];
    }
    case Type.UPDATE_TMAP: {
      return state.map(tmap => tmap.id === action.data.id ? action.data : tmap)
    }
    case Type.UPDATE_PROGRESS: {
      return state.map(tmap => tmap.id === action.data.id ? {...tmap, progress: action.data.progress} : tmap)
    }
    case Type.REMOVE_TMAP: {
      return state.filter(tmap => tmap.id !== action.data.id);
    }
    default: return state;
  }
};

export default tmapsAction;
