import * as Ducks from "./actions";

const { Type, ...Action } = Ducks;

export { Type, Action };

const datasetsAction = (state = [], action = {}) => {
  switch (action.type) {
    case Type.SET_DATASETS:
      return action.data;
    case Type.ADD_DATASET: {
      return [...state, action.data];
    }
    case Type.UPDATE_DATASET: {
      return state.map(dataset => dataset.id === action.data.id ? action.data : dataset)
    }
    case Type.UPDATE_PROGRESS: {
      return state.map(dataset => dataset.id === action.data.id ? {...dataset, progress: action.data.progress} : dataset)
    }
    case Type.REMOVE_DATASET: {
      return state.filter(dataset => dataset.id !== action.data.id);
    }
    default: return state;
  }
};

export default datasetsAction;
