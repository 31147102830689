import * as Ducks from "./actions";
import * as Helpers from "./helpers";

const { Type, ...Action } = Ducks;

export { Type, Action, Helpers };

const usersAction = (state = [], action = {}) => {
  switch (action.type) {
    case Type.SET_USERS:
      return action.data;
    case Type.ADD_USER: {
      return [...state, action.data];
    }
    case Type.UPDATE_USER: {
      return state.map(user => user.id === action.data.id ? action.data : user)
    }
    case Type.REMOVE_USER: {
      return state.filter(user => user.id !== action.data.id);
    }
    default: return state;
  }
};

export default usersAction;
