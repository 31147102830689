import * as Ducks from "./actions";
const { Type, ...Action } = Ducks;

export { Type, Action };

const faerunAction = (state = {}, action = {}) => {
  switch (action.type) {
    case Type.SET_DATA:
      return { ...state, ...action.data };
    case Type.SET_SERIES_STATE:
      return { ...state, seriesState: {...state.seriesState, [action.data.name]: {...state.seriesState[action.data.name], ...action.data.value } } };
    case Type.SET_DESCRIPTOR_DATA:
        return { ...state, descriptorData: action.data };
    
    default: return state;
  }
};

export default faerunAction;
