import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const filterSearchAction = (state = {}, action = {}) => {
  switch (action.type) {
    case Type.SET_SEARCH_FILTERS:
      return { ...state, filters: action.data }
    case Type.SET_FILTER_DATA:
      return { ...state, data: action.data }
    case FaerunType.SET_DATA:
      return {};
       
    default: return state;
  }
};

export default filterSearchAction;
