import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const nnSearchAction = (state = null, action = {}) => {
  switch (action.type) {
    case Type.SET_NN_SEARCH_RESULT:
      return action.data;
    case FaerunType.SET_DATA:
      return null;
  
    default: return state;
  }
};

export default nnSearchAction;
