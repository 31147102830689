import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

const moleculeAction = (state = {}, action = {}) => {
  switch (action.type) {
    case Type.SET_DATA:
      return { ...state, [action.data.index]: action.data };
    case FaerunType.SET_DATA:
      return {};
    
    default: return state;
  }
};

export default moleculeAction;
